.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::-moz-selection {
  background: #164193;
  color: #fff;
}
::selection {
  background: #164193;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(22, 65, 147, 0.1);
  box-sizing: border-box;
}
main {
  display: block;
  margin: 4.5em 0 0 0;
}
html {
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.875rem + 0.25
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #4c4b4b;
  font-family: "Open Sans", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 300;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 160rem;
  overflow-x: hidden;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.875rem + 0.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1.125rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1,
.style_h1 {
  letter-spacing: calc((1.4375rem + 1.0625
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.4375rem;
  color: #164193;
  font-family: "Muli", sans-serif;
  font-weight: 200;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h1,
  .style_h1 {
    font-size: calc(1.4375rem + 1.0625
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75rem) {
  h1,
  .style_h1 {
    font-size: 2.5rem;
  }
}
h2,
.style_h2 {
  letter-spacing: calc((1.1875rem + 0.4375
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #4c4b4b;
  font-family: "Muli", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h2,
  .style_h2 {
    font-size: calc(1.1875rem + 0.4375
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75rem) {
  h2,
  .style_h2 {
    font-size: 1.625rem;
  }
}
h3,
.style_h3 {
  letter-spacing: calc((1rem + 0.375
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1rem;
  color: #0D3071;
  font-family: "Muli", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h3,
  .style_h3 {
    font-size: calc(1rem + 0.375
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75rem) {
  h3,
  .style_h3 {
    font-size: 1.375rem;
  }
}
h4,
.style_h4 {
  letter-spacing: calc((1.125rem + 0.25
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.125rem;
  color: #4c4b4b;
  font-family: "Muli", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  text-transform: uppercase;
}
@media (min-width: 20rem) {
  h4,
  .style_h4 {
    font-size: calc(1.125rem + 0.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75rem) {
  h4,
  .style_h4 {
    font-size: 1.375rem;
  }
}
h5,
.style_h5 {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 0.875rem;
  color: #4c4b4b;
  font-family: "Muli", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5,
  .style_h5 {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75rem) {
  h5,
  .style_h5 {
    font-size: 1rem;
  }
}
h6,
.style_h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}
p {
  margin: 0 0 0.75em;
}
a {
  color: #4c4b4b;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #4c4b4b;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(22, 65, 147, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
  top: -2.5rem;
}
a[tabindex="-1"][id]:after {
  content: "";
  display: none;
}
a.touch_link {
  color: #4c4b4b;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #164193;
  display: inline-block;
  margin-bottom: 0.375em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #4c4b4b;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(22, 65, 147, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 400;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.75em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.5em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.5em;
}
table {
  border-bottom: 0.0625rem solid #cbcbcb;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 400;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #cbcbcb;
  padding: 0.5em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #e5e4e4;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.75em;
  padding: 0;
}
ol > li {
  padding-left: 1.5em;
  position: relative;
}
ol > li:before {
  color: #164193;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  position: absolute;
  text-align: right;
  width: 1.125em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg");
  margin: 0 0 0.75em;
  padding-left: 1.125em;
}
ul > li {
  padding-left: 0.375em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list_entry dl {
  border-bottom: 0.0625rem solid #cbcbcb;
  margin: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list_entry dt {
  border-top: 0.0625rem solid #cbcbcb;
  font-weight: 400;
  padding: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.75em 0.75em 0.75em 0;
  }
}
.defined_list_entry dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dd {
    border-top: 0.0625rem solid #cbcbcb;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.75em 0.5em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.5em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.5em;
}
figure.floatright {
  float: right;
  margin-left: 1.5em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.375em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 300;
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #4c4b4b;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.1875rem #164193 inset, 0 0 0 0.1875rem rgba(22, 65, 147, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #8c8a8a;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #8c8a8a;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #8c8a8a;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #164193;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #164193;
  color: #fff;
  display: none;
  font-weight: 400;
  margin-right: 0.375em;
  padding: 0 0.375em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #4c4b4b;
  font-family: "Muli", sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding: 0.75em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.4375rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.375em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid #cbcbcb;
  border-radius: 0;
  box-shadow: 0 0 0 #cbcbcb inset;
  color: #4c4b4b;
  margin: 0 0 1em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #164193;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #164193;
  box-shadow: 0 0 0.1875rem #164193 inset, 0 0 0 0.1875rem rgba(22, 65, 147, 0.2);
  color: #4c4b4b;
}
.input:focus,
.textarea:focus {
  border-color: #164193;
  box-shadow: 0 0 0.1875rem #164193 inset, 0 0 0 0.1875rem rgba(22, 65, 147, 0.2);
  color: #4c4b4b;
}
.input {
  height: 2.75rem;
  padding: 0 0.75em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
}
.textarea {
  line-height: 1.5;
  overflow: hidden;
  padding: 0.75em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.375em 0.75em 0.75em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #164193;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #164193;
  box-shadow: 0 0 0.1875rem #164193 inset, 0 0 0 0.1875rem rgba(22, 65, 147, 0.2);
  color: #4c4b4b;
}
.control input:focus ~ .indicator {
  border-color: #164193;
  box-shadow: 0 0 0.1875rem #164193 inset, 0 0 0 0.1875rem rgba(22, 65, 147, 0.2);
  color: #4c4b4b;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.75em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #cbcbcb;
  border-radius: 0;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #4c4b4b;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  background: #fff;
  border: 0.0625rem solid #cbcbcb;
  border-radius: 0;
  box-shadow: 0 0 0.1875rem #cbcbcb inset;
  color: #4c4b4b;
  cursor: pointer;
  display: block;
  margin: 0 0 1em;
  padding: 0 0.75em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #4c4b4b;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.75em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #164193;
}
.select.error.focus {
  border-color: #164193;
  box-shadow: 0 0 0.1875rem #164193 inset, 0 0 0 0.1875rem rgba(22, 65, 147, 0.2);
  color: #4c4b4b;
}
.select.focus {
  border-color: #164193;
  box-shadow: 0 0 0.1875rem #164193 inset, 0 0 0 0.1875rem rgba(22, 65, 147, 0.2);
  color: #4c4b4b;
}
.select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #4c4b4b;
  cursor: pointer;
  height: calc(2.75rem - (0.0625rem * 2));
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.select select::-ms-expand {
  display: none;
}
.select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #4c4b4b;
}
.select select:focus::-ms-value {
  background: none;
  color: #4c4b4b;
}
@-webkit-keyframes teaser {
  0% {
    margin-bottom: -9.75em;
    opacity: 0;
    -webkit-transform: translateY(-6em);
            transform: translateY(-6em);
  }
  25% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes teaser {
  0% {
    margin-bottom: -9.75em;
    opacity: 0;
    -webkit-transform: translateY(-6em);
            transform: translateY(-6em);
  }
  25% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(22, 65, 147, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(22, 65, 147, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(22, 65, 147, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(22, 65, 147, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.5em;
  }
  * + [role="tablist"] {
    margin-top: 1.5em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.0625rem solid #cbcbcb;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.375em;
    min-width: 100%;
    padding: 0.5em 0;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 0.75em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #164193;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #164193;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #164193;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #164193;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.5em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .contact_wrapper p {
    margin-right: 2.25em;
  }
  .contact_wrapper .address > span {
    display: inline-block;
  }
  .contact_wrapper .address {
    margin: 0;
  }
  .address h2.company_name {
    margin: 0;
  }
  .address .button {
    box-shadow: 0 0 0 2.5rem #0D3071 inset;
    overflow: hidden;
  }
  .address .button .text {
    color: #fff;
  }
  .address .button:hover,
  .address .button:focus {
    box-shadow: 0 0 0 0.125rem #0D3071 inset;
  }
  .address .button:hover .text,
  .address .button:focus .text {
    color: #0D3071;
  }
  .address .button:hover .icon,
  .address .button:focus .icon {
    fill: #0D3071;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact > strong {
    font-weight: 400;
  }
  .contact span {
    min-width: 1.25rem;
  }
  .contact.phone,
  .contact.fax {
    display: inline-block;
  }
  .address_vcard_button {
    margin: 0.75em 0;
  }
  .address_call_button {
    margin: 0.75em 0;
  }
  .startpage .header_content .slider .slider_dots {
    bottom: 2.25em;
  }
  .slider {
    line-height: 0;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 0;
  }
  .slider .slide {
    max-width: 100%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    -webkit-transition: opacity 600ms;
    transition: opacity 600ms;
    width: 100%;
    z-index: 0;
  }
  .slider .slide picture {
    display: block;
    width: 100%;
  }
  .slider .slide img {
    max-width: 100%;
    position: relative;
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 100%;
  }
  .slider .slide:first-child {
    position: relative;
  }
  .slider .slide.show {
    opacity: 1;
  }
  .slider .slider_dots {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    bottom: 1.5em;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    -webkit-box-pack: end;
            justify-content: flex-end;
    left: 50%;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
  }
  .slider .slider_dots .slider_dot,
  .slider .slider_dots .slider_next,
  .slider .slider_dots .slider_prev {
    border-radius: 50%;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    height: 0.875rem;
    margin: 0 0.3em;
    opacity: 1;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 0.875rem;
  }
  .slider .slider_dots .slider_dot {
    background-color: #fff;
  }
  .slider .slider_dots .slider_dot:focus,
  .slider .slider_dots .slider_dot:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  .slider .slider_dots .slider_dot.show {
    background-color: #164193;
    cursor: default;
  }
  .slider .slider_dots .slider_dot.show:focus,
  .slider .slider_dots .slider_dot.show:hover {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .slider .slider_dots .slider_next,
  .slider .slider_dots .slider_prev {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  .slider .slider_dots .slider_next .icon,
  .slider .slider_dots .slider_prev .icon {
    display: block;
    fill: #fff;
    height: 100%;
    width: 100%;
  }
  .slider .slider_dots .slider_next:focus,
  .slider .slider_dots .slider_prev:focus,
  .slider .slider_dots .slider_next:hover,
  .slider .slider_dots .slider_prev:hover {
    -webkit-transform: scale(1.7);
            transform: scale(1.7);
  }
  .slider .slider_dots .slider_prev .icon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .sidebar {
    margin-top: 4.5em;
  }
  .atikon_logo {
    height: 100%;
  }
  .author_hor {
    display: inline-block;
    fill: #a0a0a0;
    height: 0.5625rem;
    line-height: 0.5625rem;
    position: absolute;
    right: 0.625rem;
    top: 0.1875rem;
    width: 4.6875rem;
  }
  .author_ver {
    display: inline-block;
    fill: #4c4b4b;
    height: 4.6875rem;
    line-height: 4.6875rem;
    width: 0.5625rem;
  }
  .breadcrumbs {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.75em;
  }
  .breadcrumbs p {
    margin: 0 0.375em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    margin: 0 0.375em;
  }
  .breadcrumbs {
    height: 0.0625rem;
    left: -6250rem;
    overflow: hidden;
    position: absolute;
    top: auto;
    width: 0.0625rem;
  }
  .icon_button {
    cursor: pointer;
    display: block;
    height: 2rem;
    position: relative;
    width: 2rem;
  }
  .icon_button .icon {
    fill: #4c4b4b;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .icon_button:hover .icon,
  .icon_button:focus .icon {
    fill: #164193;
  }
  .button {
    background-color: transparent;
    border: 0;
    box-shadow: 0 0 0 2.5rem #4c4b4b inset;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: box-shadow 300ms;
    transition: box-shadow 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 0;
    font-size: 1rem;
    padding: 0.625rem 1.75rem;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
    z-index: 1;
  }
  .button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button .text {
    font-weight: 300;
    padding: 0.375em 0;
    text-transform: uppercase;
    -webkit-transition: color 300ms;
    transition: color 300ms;
  }
  .button .icon + .text {
    padding-left: 0.5em;
  }
  .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(22, 65, 147, 0.2);
    outline: 0;
    box-shadow: 0 0 0 0.25rem #4c4b4b inset;
  }
  .button:focus .text {
    color: #fff;
  }
  .button:focus .icon {
    fill: #fff;
  }
  .button:hover {
    box-shadow: 0 0 0 0.25rem #4c4b4b inset;
  }
  .button:hover .text {
    color: #4c4b4b;
  }
  .button:hover .icon {
    fill: #4c4b4b;
  }
  .button:active,
  .button.active {
    box-shadow: 0 0 0.1875rem #4c4b4b inset;
    background-color: transparent;
    border: 0;
    box-shadow: 0 0 0 2.5rem #4c4b4b inset;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: box-shadow 300ms;
    transition: box-shadow 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
    z-index: 1;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    font-weight: 300;
    padding: 0.375em 0;
    text-transform: uppercase;
    -webkit-transition: color 300ms;
    transition: color 300ms;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #164193;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.375em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #164193;
    height: auto;
    left: 0.375em;
    top: 0.375em;
    width: auto;
  }
  .company_logo {
    min-width: 18.0625rem;
    display: block;
    padding: 1.5em 0;
  }
  .company_logo.company_logo_2 {
    display: none;
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #fff;
    border-top: 0.125rem solid #cbcbcb;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner p {
    flex-basis: 100%;
    margin: 0;
    padding: 1em 0.75em 1em 1.5em;
  }
  .cookie_banner .close {
    cursor: pointer;
    flex-shrink: 0;
    margin: 0.75em;
    padding: 0 0.75em;
  }
  .cookie_banner .close > span {
    min-height: 2.75rem;
  }
  .cookie_banner .close .text {
    padding: 0 0.3em;
  }
  .download_overview {
    margin-bottom: 0.75em;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
  }
  .download_overview .touch_link .title_wrapper {
    margin-bottom: 0.375em;
    padding-top: 0;
  }
  .download_overview .title_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: block;
    flex-wrap: nowrap;
  }
  .download_overview .filesize {
    margin-left: 0.625rem;
    min-width: 5.9375rem;
  }
  .download_overview small {
    display: -webkit-box;
    display: flex;
    font-size: 55%;
  }
  .download_overview .icon {
    display: block;
    flex-shrink: 0;
    height: 0.9375rem;
    margin: 0 0.3125rem 0 0;
    width: 1.5rem;
  }
  .download_overview .icon_doc {
    fill: #22a2d9;
  }
  .download_overview .icon_xls {
    fill: #9acc21;
  }
  .download_overview .icon_pdf {
    fill: #fb4a4a;
  }
  .download_overview .icon._ppt {
    fill: #d05f0c;
  }
  .download_overview .icon_online {
    fill: #164193;
  }
  .lobu_downloads .entry {
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    margin-bottom: 1.5em;
    padding-bottom: 1.5em;
    position: relative;
  }
  .lobu_downloads .entry:after {
    background-color: #164193;
    bottom: 0;
    content: "";
    display: block;
    height: 0.3125rem;
    left: 0;
    position: absolute;
    right: 0;
  }
  .lobu_downloads .row div:nth-child(odd) .entry:after {
    display: none;
    right: -3em;
  }
  .nav_wrapper.hidden {
    visibility: hidden;
    opacity: 0;
  }
  .nav_wrapper.hidden .dropdown_menu ul {
    display: none;
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    position: relative;
  }
  .dropdown_menu > ul > li {
    display: block;
    position: relative;
  }
  .dropdown_menu > ul > li:hover > a,
  .dropdown_menu > ul > li.hover > a {
    border-color: #fff;
  }
  .dropdown_menu > ul > li:hover ul,
  .dropdown_menu > ul > li.hover ul {
    opacity: 1;
    top: 90%;
    visibility: visible;
  }
  .dropdown_menu > ul > li > a {
    -webkit-box-align: center;
            align-items: center;
    border: 0.0625rem solid #4c4b4b;
    color: #fff;
    display: -webkit-box;
    display: flex;
    font-family: "Muli", sans-serif;
    font-weight: 200;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    margin: 0 0.75em;
    padding: 0.875rem 1.375rem 0.625rem;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: border-color 300ms;
    transition: border-color 300ms;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li > a.active {
    border-color: #fff;
    color: #fff;
  }
  .dropdown_menu > ul > li ul {
    background: #0f2d67;
    left: 50%;
    opacity: 0;
    padding: 0.75em 0;
    position: absolute;
    top: 100%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: top 300ms, opacity 300ms, visibility 300ms;
    transition: top 300ms, opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: 1000;
  }
  .dropdown_menu > ul > li ul:target {
    visibility: visible;
  }
  .dropdown_menu > ul > li ul a {
    color: #fff;
    display: block;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 0.375em 1.5em;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li ul a:before {
    background-color: #1d55bf;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 100%;
    top: 0;
    -webkit-transition: right 300ms, opacity 300ms;
    transition: right 300ms, opacity 300ms;
    z-index: -1;
  }
  .dropdown_menu > ul > li ul a:hover:before,
  .dropdown_menu > ul > li ul a.active:before {
    opacity: 1;
    right: 0;
  }
  .dropdown_menu > ul > li ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li ul li:nth-of-type(even) a:before {
    left: 100%;
    right: 0;
    -webkit-transition: left 300ms, opacity 300ms;
    transition: left 300ms, opacity 300ms;
  }
  .dropdown_menu > ul > li ul li:nth-of-type(even) a:hover:before,
  .dropdown_menu > ul > li ul li:nth-of-type(even) a.active:before {
    left: 0;
  }
  footer {
    background-color: #DCE5F5;
    overflow: hidden;
    position: relative;
    z-index: 200;
  }
  footer .address {
    display: inline-block;
    font-size: 1rem;
    margin-right: 1.5em;
  }
  footer .address h2.company_name {
    font-size: 1.0625rem;
    font-weight: 400;
  }
  footer .container > .address {
    padding: 3em 0;
  }
  footer .entry > .address {
    padding: 3em 0;
  }
  footer .related_links {
    background-color: #164193;
  }
  footer .related_links ul li a {
    color: #fff;
    text-decoration: none;
  }
  footer .related_links ul li a:hover,
  footer .related_links ul li a:focus {
    text-decoration: underline;
  }
  footer .related_links ul {
    padding: 1.5em 0;
  }
  footer .additional_infos {
    padding-bottom: 3em;
    padding-top: 1.5em;
    background-attachment: fixed;
    background-image: url("../images/waves.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
  }
  footer .additional_infos:before {
    background-color: rgba(0, 0, 0, 0.15);
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  footer .additional_infos * {
    color: #fff;
  }
  footer .additional_infos > .container {
    position: relative;
    width: 100%;
    z-index: 10;
  }
  footer .additional_infos:before {
    bottom: 0;
    content: "";
    display: block;
    left: -100%;
    position: absolute;
    top: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
    width: 300%;
    z-index: 0;
  }
  footer .additional_infos .links_overview,
  footer .additional_infos a,
  footer .additional_infos .title {
    color: white;
  }
  footer .additional_infos h3.title {
    font-family: "Muli", sans-serif;
    font-size: 200%;
    font-weight: 200;
    position: relative;
    z-index: 5;
  }
  footer .additional_infos .links_overview ul {
    list-style: none;
  }
  footer .additional_infos .links_overview ul li {
    position: relative;
  }
  footer .additional_infos .links_overview ul li:before {
    background-color: #ffffff;
    content: "";
    display: inline-block;
    height: 0.0625rem;
    margin-bottom: 0.3125rem;
    margin-left: -1.5rem;
    position: relative;
    width: 1rem;
  }
  footer .additional_infos .links_overview ul li a {
    font-size: 133%;
    padding-left: 0.5rem;
    text-decoration: none;
  }
  footer .additional_infos .links_overview ul li a:hover,
  footer .additional_infos .links_overview ul li a:focus {
    text-decoration: underline;
  }
  footer .additional_infos .links_overview ul li p {
    padding-left: 0.25rem;
  }
  .form_disclaimer {
    background: #e5e4e4;
    border-radius: 0;
    color: #4c4b4b;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    height: 2.75rem;
    margin: 0 0 1em;
    position: relative;
  }
  .file.error .file_name {
    border-color: #164193;
  }
  .file .file_name {
    background: #fff;
    border: 0.0625rem solid #cbcbcb;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0.1875rem #cbcbcb inset;
    color: #4c4b4b;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    height: 2.75rem;
    line-height: calc(2.75rem - (0.0625rem * 2));
    overflow: hidden;
    padding: 0 0.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
  }
  .file .file_button {
    border-radius: 0 0 0 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 1.5em;
    z-index: 10;
    background-color: transparent;
    border: 0;
    box-shadow: 0 0 0 2.5rem #4c4b4b inset;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: box-shadow 300ms;
    transition: box-shadow 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
    z-index: 1;
  }
  .file .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file .file_button .text {
    font-weight: 300;
    padding: 0.375em 0;
    text-transform: uppercase;
    -webkit-transition: color 300ms;
    transition: color 300ms;
  }
  .file .file_button .icon + .text {
    padding-left: 0.5em;
  }
  .file .file_button.focus {
    box-shadow: 0 0 0 0.1875rem rgba(22, 65, 147, 0.2);
    box-shadow: 0 0 0 0.25rem #4c4b4b inset;
  }
  .file .file_button.focus .text {
    color: #fff;
  }
  .file .file_button.focus .icon {
    fill: #fff;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 2.75rem;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
  }
  .file [type="file"]:hover ~ .file_button {
    box-shadow: 0 0 0 0.25rem #4c4b4b inset;
  }
  .file [type="file"]:hover ~ .file_button .text {
    color: #4c4b4b;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #4c4b4b;
  }
  .file [type="file"]:active ~ .file_button {
    box-shadow: 0 0 0.1875rem #4c4b4b inset;
    background-color: transparent;
    border: 0;
    box-shadow: 0 0 0 2.5rem #4c4b4b inset;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: box-shadow 300ms;
    transition: box-shadow 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
    z-index: 1;
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file [type="file"]:active ~ .file_button .text {
    font-weight: 300;
    padding: 0.375em 0;
    text-transform: uppercase;
    -webkit-transition: color 300ms;
    transition: color 300ms;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.5em;
  }
  button {
    cursor: pointer;
    margin: 0.75em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 1.5em;
    width: 100%;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .google_maps {
    margin: 0 1.5em 0.75em 0;
  }
  .google_maps legend {
    display: none;
  }
  .google_maps .map {
    background: #e5e4e4;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.75em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    box-shadow: 0 0 0 0.25rem #bfbebe inset;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .text {
    color: #4c4b4b;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #4c4b4b;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(22, 65, 147, 0.2);
    z-index: 15;
    box-shadow: 0 0 0 0.25rem #13377d inset;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .text {
    color: #4c4b4b;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #4c4b4b;
  }
  .google_maps .travel_mode_entry {
    box-shadow: none;
    flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background-color: transparent;
    border: 0;
    box-shadow: 0 0 0 2.5rem #4c4b4b inset;
    color: #4c4b4b;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: box-shadow 300ms;
    transition: box-shadow 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
    z-index: 1;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #4c4b4b;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 300;
    padding: 0.375em 0;
    text-transform: uppercase;
    -webkit-transition: color 300ms;
    transition: color 300ms;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.5em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #fff;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio:focus .icon,
  .google_maps .travel_mode_radio .active .icon {
    fill: #4c4b4b;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0 0 0 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0 0 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0;
    color: #164193;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .google_maps .direction {
    margin: 2.25em 0 0.75em;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .button_wrapper .button {
    box-shadow: 0 0 0 2.5rem #0D3071 inset;
    overflow: hidden;
  }
  .google_maps .button_wrapper .button .text {
    color: #fff;
  }
  .google_maps .button_wrapper .button:hover,
  .google_maps .button_wrapper .button:focus {
    box-shadow: 0 0 0 0.125rem #0D3071 inset;
  }
  .google_maps .button_wrapper .button:hover .text,
  .google_maps .button_wrapper .button:focus .text {
    color: #0D3071;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.75em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid #cbcbcb;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid #cbcbcb;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.75em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .opening_times {
    margin-top: 1.5em;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  f header {
    position: relative;
  }
  .header_top {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    position: relative;
  }
  .nav_placeholder {
    height: 3.75rem;
  }
  .nav_wrapper {
    height: 3.75rem;
    background-color: #4c4b4b;
    z-index: 1000;
  }
  .nav_wrapper.fixed {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
  }
  .nav_wrapper.sticky_clone {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .header_navigation_container {
    position: relative;
  }
  .header_navigation_container .header_navigation {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .header_navigation_container .header_navigation .icon_button .icon {
    fill: #fff;
  }
  .call {
    display: block;
    margin: 0 1.5em;
  }
  .header_content {
    overflow: hidden;
    position: relative;
    /*&:before {
        //background-color: fade(@main_color, 50%);
        background-color: fade(#00a3dc, 50%);
        bottom: 0;
        content: "";
        display: block;
        height: (@spacing * 4.5);
        left: 0;
        position: absolute;
        right: 0;
        z-index: 2;

        @media (min-width: @large_grid_breakpoint) {
            bottom: -20%;
            height: auto;
            left: -20%;
            right: auto;
            top: -100%;
            transform: rotate(25deg);
            width: 73%;
        }

        @media (min-width: @xlarge_grid_breakpoint) {
            width: 66%;
        }

        @media (min-width: @xxlarge_grid_breakpoint) {
            width: auto;
            right: 50%;
        }
    }*/
  }
  .slogan {
    bottom: 0;
    left: 0;
    position: relative;
    right: 0;
    z-index: 5;
    background-color: #00a3dc;
  }
  .slogan:after {
    background: #00a3dc;
    content: "";
    height: 100%;
    left: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  .slogan:before {
    background: #00a3dc;
    content: "";
    height: 100%;
    right: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  .slogan p {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    margin-bottom: 0;
    padding: 0.75em 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .slogan_small_text {
    font-size: 0.875rem;
    color: #fff;
    display: block;
    margin-right: 0.5em;
  }
  .slogan_large_text {
    font-size: 1.25rem;
    color: #fff;
    display: block;
    line-height: 1.25;
  }
  .teaser_isartax {
    bottom: 0.75em;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 5;
  }
  .teaser_isartax_small_text {
    font-size: 0.875rem;
    color: #fff;
    display: block;
  }
  .teaser_isartax_large_text {
    font-size: 1.25rem;
    color: #fff;
    display: block;
  }
  .header_picture img {
    display: block;
    margin: 0 auto;
  }
  .img_container.arbeitnehmer_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.arbeitnehmer_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.arbeitnehmer_online_svg_2 {
    margin: 0 0 1.5em;
    max-width: 43.75rem;
  }
  .img_container.arbeitnehmer_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .arbeitnehmer_online_svg_2_small {
    display: block;
    margin: 1.5em auto;
  }
  .arbeitnehmer_online_svg_2 {
    display: none;
  }
  .arbeitnehmer_online_2_headline {
    fill: #4c4b4b;
    font-family: "Open Sans", sans-serif;
    font-size: 85%;
    text-anchor: middle;
  }
  .arbeitnehmer_online_2_headline .strong {
    fill: #4c4b4b;
    font-weight: 400;
  }
  .arbeitnehmer_online_text {
    fill: #4c4b4b;
    font-family: "Open Sans", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .arbeitnehmer_online_company_logo {
    fill: #4c4b4b;
  }
  .arbeitnehmer_online_icon {
    fill: #164193;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .arbeitnehmer_online_button .normal {
    display: block;
  }
  .arbeitnehmer_online_button .hover {
    display: none;
  }
  .arbeitnehmer_online_button:hover .normal {
    display: none;
  }
  .arbeitnehmer_online_button:hover .hover {
    display: block;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_icon {
    fill: #164193;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_euro_icon,
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_warning_icon,
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_check_icon {
    fill: #164193;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_icon {
    fill: #4c4b4b;
  }
  .arbeitnehmer_online_lines {
    fill: #164193;
  }
  .u_online_secure_login {
    margin-bottom: 1.5em;
  }
  .u_online_secure_login .button:active span,
  .u_online_secure_login .button.active span,
  .u_online_secure_login .button:focus span {
    color: #4c4b4b;
  }
  .u_online_secure_login .button:active .icon,
  .u_online_secure_login .button.active .icon,
  .u_online_secure_login .button:focus .icon {
    fill: #4c4b4b;
  }
  .img_container.u_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.u_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.u_online_svg_2 {
    margin: 0 0 1.5em;
    max-width: 43.75rem;
  }
  .img_container.u_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .u_online_svg_2_small {
    display: block;
    margin: 1.5em auto;
  }
  .u_online_svg_2 {
    display: none;
  }
  .u_online_2_headline {
    fill: #4c4b4b;
    font-family: "Open Sans", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_2_headline .strong {
    fill: #4c4b4b;
    font-weight: 400;
  }
  .u_online_text {
    fill: #4c4b4b;
    font-family: "Open Sans", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_company_logo {
    fill: #4c4b4b;
  }
  .u_online_company_logo .logo_2 {
    display: none;
  }
  .arbeitnehmer_online_company_logo .logo_2 {
    display: none;
  }
  .u_online_warning_icon {
    fill: #4c4b4b;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_euro_icon,
  .u_online_check_icon {
    fill: #4c4b4b;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_icon {
    fill: #164193;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_button .normal {
    display: block;
  }
  .u_online_button .hover {
    display: none;
  }
  .u_online_button:hover .normal {
    display: none;
  }
  .u_online_button:hover .hover {
    display: block;
  }
  .u_online_button:hover .u_online_icon {
    fill: #164193;
  }
  .u_online_button:hover .u_online_euro_icon,
  .u_online_button:hover .u_online_warning_icon,
  .u_online_button:hover .u_online_check_icon {
    fill: #164193;
  }
  .u_online_button:hover .u_online_icon {
    fill: #4c4b4b;
  }
  .u_online_lines {
    fill: #164193;
  }
  .u_online_benefits_flow h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    text-align: center;
  }
  .u_online_benefits_flow .table_cell.text {
    margin: 0 auto;
    max-width: 20rem;
  }
  .u_online_benefits_flow .arrow_large {
    display: none;
  }
  .u_online_benefits_flow .arrow_small {
    display: block;
    height: 4rem;
    margin: 0 auto;
    width: 1.875rem;
  }
  .u_online_benefits_flow .flow_text {
    display: none;
  }
  .u_online_benefits_flow .u_online_arrow_main_color {
    fill: #164193;
  }
  .u_online_benefits_flow .u_online_arrow_main_color_medium {
    fill: #8ba0c9;
  }
  .u_online_benefits_flow .header {
    display: table;
    margin: 0.75em auto;
    max-width: 20rem;
    text-align: right;
  }
  .u_online_benefits_flow .header .text {
    display: table-cell;
    margin: 0;
    padding: 0 0.75em 0 0;
    vertical-align: middle;
  }
  .u_online_benefits_flow .header .arrow_small {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .footer {
    display: table;
    margin: 0.75em auto;
    max-width: 20rem;
  }
  .u_online_benefits_flow .footer .text {
    display: table-cell;
    margin: 0;
    padding: 0 0 0 0.75em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .description {
    margin: 0 auto;
  }
  .u_online_benefits_flow .description ul {
    list-style: none;
    margin: 1.5em;
  }
  .u_online_benefits_flow .description li {
    margin: 0.8em 0;
    padding-left: 2.125rem;
    position: relative;
  }
  .u_online_benefits_flow .description li:before {
    background-color: #164193;
    background-image: url("../images/u_online/u_online_check.svg");
    content: "";
    display: block;
    height: 1.5rem;
    left: 0;
    position: absolute;
    width: 1.5rem;
  }
  .u_online_benefits_flow .lock {
    background: #e5e4e4;
    display: table;
    margin: 0 auto 0.75em;
    padding: 1.5em;
  }
  .u_online_benefits_flow .lock .text {
    display: table-cell;
    padding-left: 1.5em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .lock:before {
    background: url("../images/u_online/u_online_lock.svg") no-repeat 50% 50%;
    content: "";
    display: table-cell;
    height: 1.75rem;
    width: 1.75rem;
  }
  .u_online_benefits_flow .benefits_button {
    border: 0;
    cursor: pointer;
    display: table;
    margin: 0 auto;
    min-width: 14.375rem;
    text-decoration: none;
  }
  .u_online_benefits_flow .benefits_button > span {
    display: table-cell;
  }
  .u_online_benefits_flow .benefits_button .text {
    border-bottom-width: 0.125rem;
    border-color: #164193;
    border-left-width: 0.0625rem;
    border-radius: 0 0 0 0;
    border-style: solid;
    border-top-width: 0.0625rem;
    color: #4c4b4b;
    display: block;
    font-size: 130%;
    font-weight: 500;
    line-height: 2.75rem;
    margin-right: 0.1875rem;
    padding: 0 1.5em;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: border 300ms;
    transition: border 300ms;
  }
  .u_online_benefits_flow .benefits_button:after {
    background-color: #164193;
    background-image: url("../images/u_online/u_online_button_arrow.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 60%;
    border-radius: 0 0 0 0;
    content: "";
    display: table-cell;
    height: 2.75rem;
    position: relative;
    -webkit-transition: background-color 300ms;
    transition: background-color 300ms;
    vertical-align: middle;
    width: 2.75rem;
    z-index: 1;
  }
  .u_online_benefits_flow .benefits_button.visible .text {
    border-color: #4c4b4b;
  }
  .u_online_benefits_flow .benefits_button.visible:after {
    background-color: #4c4b4b;
    border-radius: 0 0 0 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .benefits_button:hover .text {
    border-color: #0f2d67;
  }
  .u_online_benefits_flow .benefits_button:hover:after {
    background-color: #0f2d67;
  }
  .u_online_benefits_flow .flow_image {
    border: 0.25rem solid #164193;
    border-radius: 50%;
    box-sizing: content-box;
    height: 15.625rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 15.625rem;
  }
  .u_online_benefits_flow .flow_image .text {
    background: rgba(22, 65, 147, 0.85);
    bottom: 0;
    color: #fff;
    left: 0;
    padding: 0.8em 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .u_online_benefits_flow .flow_image img {
    border-radius: 50%;
  }
  .infolist .col2 .infolist_overview,
  .infolist .col2 .download_overview {
    color: #fff;
    margin: 0;
    padding-bottom: 1.5em;
    padding-left: 1.5em;
    position: relative;
  }
  .infolist .col2 .infolist_overview p,
  .infolist .col2 .download_overview p {
    margin: 0;
    padding-bottom: 0.75em;
  }
  .infolist .col2 .infolist_overview small,
  .infolist .col2 .download_overview small {
    color: #fff;
  }
  .infolist .col2 .infolist_overview .title,
  .infolist .col2 .download_overview .title,
  .infolist .col2 .infolist_overview a,
  .infolist .col2 .download_overview a {
    color: #fff;
  }
  .infolist .col2 .infolist_overview .title,
  .infolist .col2 .download_overview .title {
    text-transform: uppercase;
  }
  .infolist .col2 .infolist_overview .overview_title,
  .infolist .col2 .download_overview .overview_title {
    color: #fff;
  }
  .infolist .col2 .infolist_overview:after,
  .infolist .col2 .download_overview:after {
    background-color: #164193;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: -500%;
    top: 0;
    z-index: -1;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .steuerlexikon .amount {
    display: table-cell;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0.75em 0;
  }
  .infolist_top_link a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    text-align: center;
  }
  .infolist_top_link .icon {
    display: block;
    fill: #164193;
    height: 1.5rem;
    margin-right: 1.5em;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    width: 1.5rem;
  }
  .disclaimer {
    background: #e5e4e4;
    border-radius: 0;
    color: #4c4b4b;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .infolist_download {
    -webkit-box-align: center;
            align-items: center;
    background: #e5e4e4;
    border-radius: 0;
    color: #4c4b4b;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .infolist_download .infolist_title {
    font-weight: 400;
  }
  .infolist_download p {
    margin-right: 1.5em;
  }
  .checklist_entry {
    border-top: 0.0625rem solid #cbcbcb;
    margin-bottom: 0.75em;
    padding-top: 0.75em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  .kanzlei_im_netz {
    margin: 0 0 1.5em;
  }
  .kanzlei_im_netz a:after {
    clear: both;
  }
  .kanzlei_im_netz a:after,
  .kanzlei_im_netz a:before {
    content: "";
    display: table;
  }
  .kanzlei_im_netz .icon {
    border-radius: 0;
    float: left;
    height: 3.625rem;
    margin: 0.75em 1.5em 0 0;
    width: 3.625rem;
  }
  .kanzlei_im_netz .icon_mobile,
  .kanzlei_im_netz .icon_mail,
  .kanzlei_im_netz .icon_vimeo {
    background: #e5e4e4;
    fill: #4c4b4b;
    padding: 0.3125rem;
  }
  .kanzlei_im_netz .icon_mobile svg,
  .kanzlei_im_netz .icon_mail svg,
  .kanzlei_im_netz .icon_vimeo svg {
    height: 3rem;
    width: 3rem;
  }
  .kanzlei_im_netz .icon_vimeo {
    background: #164193;
    fill: #fff;
  }
  .kanzlei_im_netz .icon_instagram {
    background: #E1306C;
    fill: #fff;
    padding: 0.625rem;
  }
  .kanzlei_im_netz .description {
    display: block;
    overflow: hidden;
  }
  .inner_body {
    overflow: hidden;
    position: relative;
  }
  .startpage h1 {
    display: none;
  }
  .teaser_isartax {
    top: 20% !important;
  }
  .isartax_banner {
    padding: 1rem 0;
    background: #d9d9d9;
    box-shadow: inset 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
    -webkit-box-align: center;
            align-items: center;
  }
  .isartax_banner a {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    width: 100%;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
  }
  .isartax_banner p {
    font-weight: bold;
    padding-right: 1rem;
    margin-bottom: 0;
  }
  .newsletter_quick_subscribe form {
    display: block;
  }
  .newsletter_quick_subscribe .entry {
    width: 100%;
  }
  .newsletter_quick_subscribe input[type="email"] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: #fff;
    border: 0.0625rem solid #cbcbcb;
    border-radius: 0;
    box-shadow: 0 0 0 #cbcbcb inset;
    color: #4c4b4b;
    height: 2.75rem;
    margin: 0;
    padding: 0 0.75em;
    -webkit-transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms;
    transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms;
    width: 100%;
  }
  .newsletter_quick_subscribe input[type="email"]:focus {
    background: #fff;
    border-color: #164193;
    box-shadow: 0 0 0.1875rem #164193 inset, 0 0 0 0.1875rem rgba(22, 65, 147, 0.2);
    color: #4c4b4b;
    outline: 0;
  }
  .newsletter_quick_subscribe button {
    background-color: transparent;
    border: 0;
    box-shadow: 0 0 0 2.5rem #4c4b4b inset;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    text-decoration: none;
    -webkit-transition: box-shadow 300ms;
    transition: box-shadow 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0 1.5em;
  }
  .newsletter_quick_subscribe button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
    z-index: 1;
  }
  .newsletter_quick_subscribe button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .newsletter_quick_subscribe button .text {
    font-weight: 300;
    padding: 0.375em 0;
    text-transform: uppercase;
    -webkit-transition: color 300ms;
    transition: color 300ms;
  }
  .newsletter_quick_subscribe button .icon + .text {
    padding-left: 0.5em;
  }
  .newsletter_quick_subscribe button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(22, 65, 147, 0.2);
    box-shadow: 0 0 0 0.25rem #4c4b4b inset;
  }
  .newsletter_quick_subscribe button:focus .text {
    color: #fff;
  }
  .newsletter_quick_subscribe button:focus .icon {
    fill: #fff;
  }
  .toggle_offcanvas_menu {
    display: inline-block;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 112.5%;
    font-weight: 400;
    height: 2.75rem;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    height: 2rem;
    margin-right: 0.75em;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 2rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #fff;
    display: block;
    height: 0.0625rem;
    position: absolute;
    -webkit-transform-origin: left;
            transform-origin: left;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.125rem;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transition: width 300ms, top 300ms, -webkit-transform 300ms;
    transition: width 300ms, top 300ms, -webkit-transform 300ms;
    transition: transform 300ms, width 300ms, top 300ms;
    transition: transform 300ms, width 300ms, top 300ms, -webkit-transform 300ms;
    visibility: visible;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 49%;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.125rem;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transition: width 300ms, bottom 300ms, -webkit-transform 300ms;
    transition: width 300ms, bottom 300ms, -webkit-transform 300ms;
    transition: transform 300ms, width 300ms, bottom 300ms;
    transition: transform 300ms, width 300ms, bottom 300ms, -webkit-transform 300ms;
    width: 100%;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(76, 75, 75, 0.5);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms, visibility 240ms;
    transition: opacity 240ms, visibility 240ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background-color: #4c4b4b;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    display: block;
    min-height: 100%;
    opacity: 0;
    padding: 1.5em 0;
    position: absolute;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 15.625rem;
    z-index: 5000;
    left: 0;
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    padding: 0 1.5em;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a ~ ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    border: 0.0625rem solid #4c4b4b;
    color: #fff;
    display: block;
    font-size: 112.5%;
    font-weight: 300;
    line-height: 2.75rem;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:hover,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:focus {
    border: 0.0625rem solid #fff;
    padding-left: 0.75em;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.active ul {
    padding-left: 0.75em;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #fff;
    display: block;
    padding: 0.375em 1.5em 0.375em 0;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    text-decoration: underline;
  }
  .onlinetools_popup_overview {
    margin-top: 2.25em;
  }
  .col1 .onlinetools_popup_overview,
  .col2 .onlinetools_popup_overview {
    margin-top: 0;
  }
  .onlinetools_popup_overview .entry {
    -webkit-box-align: center;
            align-items: center;
    background-color: #DCE5F5;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin: 0 0 3em;
    padding: 3em;
    text-align: center;
    -webkit-transition: background-color 300ms linear, -webkit-transform 300ms linear;
    transition: background-color 300ms linear, -webkit-transform 300ms linear;
    transition: background-color 300ms linear, transform 300ms linear;
    transition: background-color 300ms linear, transform 300ms linear, -webkit-transform 300ms linear;
  }
  .onlinetools_popup_overview .entry:hover {
    background-color: #c8d6ef;
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }
  .onlinetools_popup_overview .entry:hover .button {
    background: transparent;
  }
  .onlinetools_popup_overview .entry .title {
    color: #4c4b4b;
    padding-top: 0;
    text-transform: uppercase;
  }
  .onlinetools_popup_overview .entry .button {
    background: #4c4b4b;
    border: 0;
    border-radius: 0;
    margin-top: 0.375em;
    text-transform: uppercase;
  }
  .onlinetools_popup_overview p {
    width: 100%;
  }
  .onlinetools_popup_overview .description {
    margin-bottom: 1.5em;
    width: 100%;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.375em;
    padding: 0;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(76, 75, 75, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.5em;
  }
  .popup_close {
    background: #164193;
    border-radius: 0 0 0 0;
    float: right;
    height: 2.2rem;
    margin-right: 0.0625rem;
    padding: 0.42857143em;
    text-align: center;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(76, 75, 75, 0.5);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.2rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .open_street_map .map {
    background-color: #e5e4e4;
    cursor: move;
    height: 21.875rem;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(229, 228, 228, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.5em 3em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.5em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.75em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.5em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #4c4b4b;
    border: 0.0625rem solid #4c4b4b;
    border-radius: 0;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 400;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #4c4b4b;
    border: 0.0625rem solid #4c4b4b;
    box-shadow: 0 0 0 0.1875rem rgba(22, 65, 147, 0.2);
    color: #fff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #4c4b4b;
    border: 0.0625rem solid #4c4b4b;
    color: #4c4b4b;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.75em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #e5e4e4;
    border-radius: 0;
    color: #4c4b4b;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .opening_times .title {
    padding-top: 0.75em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.25em;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #cbcbcb;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  form[role="search"] {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] button {
    border-radius: 0 0 0 0;
    margin: 0;
    min-width: 2.75rem;
    padding: 0;
    width: 2.75rem;
    background-color: transparent;
    border: 0;
    box-shadow: 0 0 0 2.5rem #4c4b4b inset;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: box-shadow 300ms;
    transition: box-shadow 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  form[role="search"] button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
    z-index: 1;
  }
  form[role="search"] button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  form[role="search"] button .text {
    font-weight: 300;
    padding: 0.375em 0;
    text-transform: uppercase;
    -webkit-transition: color 300ms;
    transition: color 300ms;
  }
  form[role="search"] button .icon + .text {
    padding-left: 0.5em;
  }
  form[role="search"] button[disabled] {
    background-color: #e5e4e4;
    border-color: #d8d7d7;
    color: #cbcbcb;
  }
  form[role="search"] button[disabled] .icon {
    fill: #cbcbcb;
  }
  form[role="search"] button:focus,
  form[role="search"] button:hover {
    box-shadow: 0 0 0 0.1875rem rgba(22, 65, 147, 0.2);
    position: relative;
    z-index: 10;
    box-shadow: 0 0 0 0.25rem #164193 inset;
  }
  form[role="search"] button:focus .text,
  form[role="search"] button:hover .text {
    color: #fff;
  }
  form[role="search"] button:focus .icon,
  form[role="search"] button:hover .icon {
    fill: #fff;
  }
  form[role="search"] button .icon {
    height: calc(2.75rem - (0.0625rem * 2));
  }
  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    border: 0.0625rem solid #cbcbcb;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0 #cbcbcb inset;
    color: #4c4b4b;
    flex-basis: 100%;
    height: 2.75rem;
    margin: 0;
    padding: 0 0.75em;
    -webkit-transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
    transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
  }
  input[type="search"]:focus {
    background: #fff;
    border-color: #164193;
    box-shadow: 0 0 0.1875rem #164193 inset, 0 0 0 0.1875rem rgba(22, 65, 147, 0.2);
    color: #4c4b4b;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search dl {
    margin: 0 0 0.75em;
    padding-top: 0.375em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  .toggle_search_box {
    margin: 0.75em 1.5em;
    position: relative;
  }
  .toggle_search_box .open_search_box {
    display: block;
  }
  .toggle_search_box .close_search_box {
    display: none;
  }
  .toggle_search_box:after {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    height: 1.5625rem;
    left: 0;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    top: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
    width: 1.5625rem;
  }
  .toggle_search_box:hover:after {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .search_box {
    -webkit-backdrop-filter: blur(0.125rem);
    -webkit-box-align: center;
            align-items: center;
    background: #fff;
    border-bottom: 0.0625rem solid #cbcbcb;
    display: -webkit-box;
    display: flex;
    height: 0;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    padding: 0 calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 9999;
  }
  .search_box .close_search_box_wrapper {
    display: block;
    position: relative;
  }
  .search_box .close_search_box_wrapper label {
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
  }
  .search_box .close_search_box_wrapper .close_search_box {
    display: block;
    fill: #164193;
    height: 2.75rem;
    padding: 0.25rem;
    width: 2.75rem;
    z-index: 1000;
  }
  .search_box .close_search_box_wrapper .close_search_box .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus,
  .search_box .close_search_box_wrapper .close_search_box:hover {
    outline: 0;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus .icon,
  .search_box .close_search_box_wrapper .close_search_box:hover .icon {
    fill: #164193;
  }
  .search_box form[role="search"] {
    display: -webkit-box;
    display: flex;
    opacity: 0;
    padding: 1.5em 0;
    position: relative;
    -webkit-transform: translateY(-6.25rem);
            transform: translateY(-6.25rem);
    -webkit-transition: none;
    transition: none;
    visibility: hidden;
    width: 100%;
    z-index: 1000;
  }
  .search_box form[role="search"] button {
    background-color: #fff;
    border-color: #4c4b4b;
    height: 2.75rem;
    padding: 0 0.375em;
  }
  .search_box form[role="search"] button:focus .icon,
  .search_box form[role="search"] button:hover .icon {
    fill: #164193;
  }
  .search_box input[type="search"] {
    border-color: #4c4b4b;
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    height: 2.75rem;
    padding-right: 0;
  }
  .search_box_state {
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
  }
  .search_box_state:checked ~ .search_box,
  .search_box_state:target ~ .search_box {
    height: auto;
    margin-bottom: 1.5em;
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box .close_search_box_wrapper label,
  .search_box_state:target ~ .search_box .close_search_box_wrapper label {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box form[role="search"],
  .search_box_state:target ~ .search_box form[role="search"] {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box {
    display: none;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box {
    display: block;
  }
  .social_media_legal_notice {
    padding: 1.5em;
  }
  .social_media_legal_notice .company_logo {
    margin: 0 auto;
    max-width: 25rem;
    text-align: center;
  }
  .social_media_links {
    z-index: 2000;
    display: none;
  }
  .social_media_links ul {
    display: -webkit-box;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .social_media_links li {
    height: 2.125rem;
    list-style: none;
    margin: 0 0 0 0.75em;
    width: 2.125rem;
  }
  .social_media_links a {
    border-radius: 0;
    display: inline-block;
  }
  .social_media_links a .icon {
    display: block;
    fill: #fff;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_links a.video {
    background: #164193;
  }
  .social_media_links a.instagram {
    background: #E1306C;
    border-radius: 3px;
  }
  .social_media_links a.instagram .icon {
    padding: 0.3125rem;
  }
  .social_media_links .label {
    left: -8%;
    position: absolute;
    top: -50%;
    width: 4.375rem !important;
  }
  .social_media_links .label_wrapper_2 .label {
    left: -8%;
    position: absolute;
    top: -50%;
    width: 8.75rem !important;
  }
  .social_media_links .label_wrapper_1 .label {
    left: -8%;
    position: absolute;
  }
  footer .social_media_links {
    display: block;
    padding: 1.5em 0;
  }
  footer .social_media_links .label_wrapper {
    position: absolute;
    right: 10%;
  }
  footer .social_media_links .label_wrapper_2 {
    position: absolute;
    right: 40%;
    margin-top: 1em;
    display: none;
  }
  .header_top .facebook,
  .header_top .twitter,
  .header_top .xing,
  .header_top .linkedin,
  .header_top .instagram {
    margin-top: 0.6rem;
  }
  .social_media_share ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 1.5em 0 0.75em 0;
    padding: 0;
  }
  .social_media_share li {
    list-style: none;
    margin: 0.375em 0;
    padding: 0;
  }
  .social_media_share a {
    color: #4c4b4b;
    cursor: pointer;
    display: block;
    margin-right: 0.75em;
    padding-right: 0.75em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .social_media_share a > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
  }
  .social_media_share a .icon {
    border-radius: 0;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_share a .text {
    font-weight: 300;
    padding-left: 0.75em;
  }
  .social_media_share a.print {
    display: none;
  }
  .social_media_share a.print .icon {
    background: #164193;
    text-align: center;
  }
  .social_media_share a.print .icon svg {
    fill: #fff;
    height: 2.125rem;
    width: 1.5rem;
  }
  .steuernews_issues ul,
  .steuernews_issues li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .steuernews_issues a {
    color: #fff;
    padding: 0.9375rem 1.25rem;
    text-decoration: none;
  }
  .steuernews_issues a:hover,
  .steuernews_issues a:focus {
    background-color: transparent;
    color: #4c4b4b;
  }
  .steuernews_issues a.active {
    box-shadow: 0 0 0 1.875rem #7f7e7e inset;
    color: #DCE5F5;
  }
  .steuernews_issues li {
    padding-bottom: 0.9375rem;
  }
  aside .steuernews_issues ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .steuernews_article_slider {
    display: none;
  }
  .steuernews_navigation {
    margin: 1.5em 0;
  }
  .steuernews_navigation nav ul:after {
    clear: both;
  }
  .steuernews_navigation nav ul:after,
  .steuernews_navigation nav ul:before {
    content: "";
    display: table;
  }
  .steuernews_navigation nav li {
    float: left;
  }
  .steuernews_navigation nav li:first-child a {
    padding-left: 0;
  }
  .steuernews_navigation nav a {
    display: inline-block;
    line-height: 1.5;
    padding: 0 1.5em;
  }
  .steuernews_content [href*="thema"] {
    color: #4c4b4b;
  }
  .steuernews_preview {
    margin-bottom: 0.75em;
  }
  .steuernews_preview .entry {
    border-top: 0.3125rem solid #DCE5F5;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 1.5em;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .steuernews_preview .entry:hover,
  .steuernews_preview .entry:focus {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  .steuernews_preview .entry:hover .teaser_text,
  .steuernews_preview .entry:focus .teaser_text {
    border-color: #164193;
  }
  .steuernews_preview .image {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0;
    max-height: 8.75rem;
    -webkit-box-ordinal-group: 2;
            order: 1;
    overflow: hidden;
  }
  .steuernews_preview .image img {
    margin-top: -4.375rem;
  }
  .steuernews_preview .teaser_text {
    border-bottom: 0.3125rem solid #DCE5F5;
    margin-bottom: 3em;
    margin-top: auto;
    -webkit-box-ordinal-group: 3;
            order: 2;
    -webkit-transition: border-color 300ms;
    transition: border-color 300ms;
  }
  .steuernews_preview .teaser_text .title {
    text-decoration: none;
  }
  .steuernews_preview .video_play {
    position: relative;
  }
  .steuernews_preview .video_play .icon {
    fill: #164193;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_search {
    margin: 0 0 1.5em;
  }
  .steuernews_search .search {
    margin: 0 0 0.75em;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_image {
    display: none;
  }
  .news_image + * {
    padding-top: 0;
  }
  .steuernews_video_overview {
    margin-bottom: 1.5em;
  }
  .steuernews_video_overview .entry {
    display: inline-block;
  }
  .steuernews_video_overview .image {
    margin-bottom: 0.75em;
    position: relative;
  }
  .steuernews_video_overview .video_play {
    position: relative;
  }
  .steuernews_video_overview .video_play .icon {
    fill: #164193;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_contact_person {
    background: #e5e4e4;
    border-radius: 0;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .steuernews_contact_person .team_entry {
    display: -webkit-box;
    display: flex;
  }
  .steuernews_contact_person .team_entry figure {
    display: block;
  }
  .steuernews_contact_person .contact_person_entry {
    height: auto;
    margin-top: 1.5em;
    position: relative;
  }
  .steuernews_contact_person .more_team_contacts {
    background: #fff;
    border-radius: 0;
    color: #164193;
    display: block;
    line-height: 1.5;
    margin: 1em 0;
    padding: 0.75em;
    text-align: center;
  }
  .steuernews_newsletter_subscribe {
    background: #e5e4e4;
    border-radius: 0;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .steuernews_newsletter_subscribe .disclaimer {
    background-color: #fff;
  }
  .steuernews_sidebar {
    border-top: 0.3125rem solid #DCE5F5;
  }
  .steuernews_current_issue {
    margin-bottom: 0.75em;
  }
  .steuernews_current_issue a.active {
    color: #164193;
    text-decoration: none;
  }
  .highlight_keyword {
    background: #164193;
    color: #fff;
    outline: 0.125rem solid #164193;
  }
  .team_overview .team_entry {
    border-bottom: 0.5rem solid #DCE5F5;
    margin-bottom: 5.25em;
    position: relative;
  }
  .team_overview .team_entry .info_wrapper {
    -webkit-box-align: stretch;
            align-items: stretch;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .team_overview .team_entry.more_margin {
    margin-bottom: 7.5em;
  }
  .team_overview .team_entry.highlight {
    border-bottom-color: #164193;
  }
  .team_overview .team_entry.highlight .button {
    box-shadow: 0 0 0 2.5rem #164193 inset;
  }
  .team_overview .team_entry.highlight .button:hover,
  .team_overview .team_entry.highlight .button:focus {
    box-shadow: 0 0 0 0.3125rem #164193 inset;
  }
  .team_overview .team_entry.highlight .button:hover svg,
  .team_overview .team_entry.highlight .button:focus svg {
    fill: #4c4b4b;
  }
  .team_overview .team_entry.highlight .button svg {
    fill: #fff;
  }
  .team_overview .buttons {
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    margin: 0;
    position: absolute;
    right: 0;
  }
  .team_overview .buttons .call {
    margin-right: 0.375em;
  }
  .team_overview .buttons .button {
    box-shadow: 0 0 0 2.5rem #DCE5F5 inset;
    margin-bottom: 0;
    margin-left: 0.75em;
    z-index: 1;
  }
  .team_overview .buttons .button:hover,
  .team_overview .buttons .button:focus {
    background-color: #fff;
    box-shadow: 0 0 0 0.3125rem #DCE5F5 inset;
  }
  .team_overview .buttons .icon {
    fill: #4c4b4b;
  }
  .team_overview .buttons .text {
    display: none;
  }
  .team_overview .title {
    color: #164193;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
  .team_overview .photo {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0 1.5em 0 0;
    margin-bottom: 0.75em;
    position: relative;
    width: 10.625rem;
  }
  .team_overview .photo img {
    display: block;
    margin: 0;
    width: 100%;
  }
  .team_overview .info {
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    -webkit-box-pack: center;
            justify-content: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
            align-items: flex-start;
    margin: 1.5em 0 4.28571429em;
    position: relative;
  }
  .team_overview .info .job_title {
    margin-top: 0.75em;
  }
  .team_overview .info a {
    text-decoration: none;
  }
  .team_overview .contact {
    /*.text {
            margin-left: (@spacing / 2);
        }*/
  }
  .team_overview .contact strong {
    color: #164193;
  }
  .team_overview .contact.contact_mail {
    margin-bottom: 0.375em;
  }
  .teaser_headline h2 {
    letter-spacing: calc((1.4375rem + 1.0625
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
    font-size: 1.4375rem;
    color: #164193;
    font-weight: 200;
    text-align: center;
  }
  .special_teaser_overview {
    -webkit-box-align: stretch;
            align-items: stretch;
    color: #4c4b4b;
    flex-basis: auto;
    font-weight: lighter;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 3em 0;
    text-align: center;
  }
  .special_teaser_overview a {
    color: #4c4b4b;
    text-decoration: none;
  }
  .special_teaser_overview .teaser {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin: 1.5em 0.75em;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
  }
  .special_teaser_overview .teaser .teaser_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .special_teaser_overview .teaser .teaser_wrapper p {
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    opacity: 0;
    -webkit-transform: translateY(-3.125rem);
            transform: translateY(-3.125rem);
    -webkit-transition: opacity 3s, -webkit-transform 3s;
    transition: opacity 3s, -webkit-transform 3s;
    transition: transform 3s, opacity 3s;
    transition: transform 3s, opacity 3s, -webkit-transform 3s;
  }
  .special_teaser_overview .teaser .teaser_top {
    background: #DCE5F5;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
  }
  .special_teaser_overview .teaser:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  .special_teaser_overview .teaser:hover .teaser_top {
    background: #c8d6ef;
  }
  .special_teaser_overview .teaser:hover p {
    border-bottom: 0.625rem solid #c8d6ef;
  }
  .special_teaser_overview .teaser .icon {
    display: -webkit-box;
    display: flex;
    fill: #4c4b4b;
    -webkit-box-pack: center;
            justify-content: center;
    padding-top: 2.25em;
    z-index: 30 !important;
  }
  .special_teaser_overview .teaser .icon svg {
    height: 4.0625rem;
    width: 4.0625rem;
  }
  .special_teaser_overview .teaser .title {
    color: #4c4b4b;
    font-family: "Open Sans", sans-serif;
    font-weight: lighter;
    margin-bottom: 0;
    padding-bottom: 2.25em;
    text-transform: uppercase;
    z-index: 30 !important;
  }
  .special_teaser_overview .teaser p {
    border-bottom: 0.625rem solid #DCE5F5;
    -webkit-box-flex: 1;
            flex-grow: 1;
    overflow: hidden;
    padding: 2.25em 0.75em;
    -webkit-transition: all 3s linear;
    transition: all 3s linear;
  }
  .special_teaser_overview .teaser.animated .teaser_wrapper p {
    opacity: 1;
    -webkit-transform: translateY(0rem);
            transform: translateY(0rem);
  }
  .sidebar_teaser_overview .teaser,
  .uonline_teaser .teaser {
    background-color: #DCE5F5;
    display: block;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    margin-bottom: 1.5em;
    overflow: hidden;
    padding: 6em 3em;
    position: relative;
    text-align: center;
    z-index: 1;
  }
  .sidebar_teaser_overview .teaser .icon,
  .uonline_teaser .teaser .icon {
    bottom: -6.3125rem;
    fill: #fff;
    height: 17.375rem;
    position: absolute;
    right: -6.625rem;
    z-index: -1;
  }
  .sidebar_teaser_overview .teaser .button,
  .uonline_teaser .teaser .button {
    background-color: transparent;
    border: 0;
    overflow: hidden;
  }
  .sidebar_teaser_overview .teaser .button > span,
  .uonline_teaser .teaser .button > span {
    z-index: 1;
  }
  .uonline_teaser {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .startpage_light_teaser {
    overflow: hidden;
    position: relative;
    z-index: 200;
  }
  .startpage_light_teaser .teaser {
    overflow: hidden;
    padding: 6em 0;
    position: relative;
  }
  .startpage_light_teaser .teaser .icon {
    bottom: -6.25rem;
    height: 15.625rem;
    width: 15.625rem;
    display: block;
    fill: rgba(220, 229, 245, 0.4);
    position: absolute;
    right: 0;
    z-index: -1;
  }
  .startpage_light_teaser .teaser .button {
    box-shadow: 0 0 0 0.125rem #0D3071 inset;
    overflow: hidden;
  }
  .startpage_light_teaser .teaser .button .text {
    color: #0D3071;
  }
  .startpage_light_teaser .teaser .button:hover,
  .startpage_light_teaser .teaser .button:focus {
    box-shadow: 0 0 0 2.5rem #0D3071 inset;
  }
  .startpage_light_teaser .teaser .button:hover .text,
  .startpage_light_teaser .teaser .button:focus .text {
    color: #fff;
  }
  .startpage_dark_teaser {
    background-color: #0D3071;
    padding: 3em 0;
    position: relative;
    z-index: 150;
  }
  .startpage_dark_teaser:before,
  .startpage_dark_teaser:after {
    background-color: #0D3071;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  .startpage_dark_teaser:before {
    right: 99%;
  }
  .startpage_dark_teaser:after {
    left: 99%;
  }
  .startpage_dark_teaser .teaser {
    -webkit-box-align: center;
            align-items: center;
    color: #fff;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    position: relative;
  }
  .startpage_dark_teaser .teaser .icon {
    bottom: -6.25rem;
    height: 15.625rem;
    width: 15.625rem;
    display: block;
    fill: rgba(255, 255, 255, 0.05);
    position: absolute;
    right: 0;
    z-index: 10;
  }
  .startpage_dark_teaser .teaser .title {
    color: #fff;
    margin: 0;
    padding: 0;
  }
  .startpage_dark_teaser .teaser .button {
    position: relative;
    z-index: 20;
  }
  .startpage_dark_teaser .teaser p {
    margin: 0;
  }
  .startpage_dark_teaser .teaser .button {
    box-shadow: 0 0 0 0.125rem #fff inset;
    margin-left: auto;
    margin-top: 1.5em;
    outline: 0;
  }
  .startpage_dark_teaser .teaser .button .text {
    color: #fff;
  }
  .startpage_dark_teaser .teaser .button:hover,
  .startpage_dark_teaser .teaser .button:focus {
    box-shadow: 0 0 0 2.5rem #fff inset;
  }
  .startpage_dark_teaser .teaser .button:hover .text,
  .startpage_dark_teaser .teaser .button:focus .text {
    color: #0D3071;
  }
  .counter_image {
    background-attachment: fixed;
    background-image: url("../images/waves.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
  }
  .counter_image:before {
    background-color: rgba(0, 0, 0, 0.15);
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .counter_image * {
    color: #fff;
  }
  .counter_image > .container {
    position: relative;
    width: 100%;
    z-index: 10;
  }
  .counter {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    overflow: hidden;
  }
  #counter {
    overflow: hidden;
    width: 100%;
  }
  .counter_overview {
    color: #fff;
    display: -webkit-box;
    display: flex;
    padding: 2.25em 0 1.875em;
    text-align: center;
    z-index: 1;
  }
  .counter_overview ul {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-wrap: wrap;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .counter_overview ul li {
    -webkit-box-flex: 1;
            flex-grow: 1;
    list-style: none;
    margin: 1.5em;
  }
  .counter_overview ul li .number_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: center;
            justify-content: center;
    overflow: hidden;
  }
  .counter_overview ul li .number {
    letter-spacing: calc((2.25rem + 2.75
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
    font-size: 2.25rem;
    font-weight: 300;
    overflow: hidden;
  }
  .counter_overview ul li .text {
    letter-spacing: calc((0.875rem + 0.625
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
    font-size: 0.875rem;
    font-weight: 200;
    overflow: hidden;
  }
  .odometer-auto-theme .odometer-digit {
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }
  .odometer-auto-theme .odometer-digit .odometer-digit-spacer {
    display: inline-block;
    vertical-align: auto;
    visibility: hidden;
  }
  .odometer-auto-theme .odometer-digit .odometer-digit-inner {
    bottom: 0;
    display: block;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: left;
    top: 0;
  }
  .odometer-auto-theme .odometer-digit .odometer-ribbon {
    display: block;
  }
  .odometer-auto-theme .odometer-digit .odometer-ribbon-inner {
    -webkit-backface-visibility: hidden;
    display: block;
  }
  .odometer-auto-theme .odometer-digit .odometer-value {
    -webkit-transform: translateZ(0);
    display: block;
  }
  .odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value {
    position: absolute;
  }
  .odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    transition: -webkit-transform 2s;
    transition: transform 2s;
    transition: transform 2s, -webkit-transform 2s;
  }
  .odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  .odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  .odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: -webkit-transform 2s;
    transition: -webkit-transform 2s;
    transition: transform 2s;
    transition: transform 2s, -webkit-transform 2s;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #e5e4e4;
    border: 0.0625rem solid #cbcbcb;
    border-radius: 0;
    color: #4c4b4b;
    font-size: 85%;
    font-weight: 300;
    line-height: 2.0625rem;
    padding: 0 0.75em;
    position: absolute;
    right: 0;
    text-align: center;
    top: -2.4375rem;
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #e5e4e4;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #cbcbcb;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
}
@media screen and (min-width: 61.875rem) {
  .address .address_call_button {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .contact > strong {
    min-width: 5.625rem;
  }
}
@media screen and (min-width: 20rem) {
  .slider .slider_dots {
    padding: 0 calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .slider .slider_dots {
    padding: 0 3.125rem;
    bottom: 2.25em;
  }
}
@media screen and (max-width: 47.4375rem) {
  .slider .slider_dots {
    display: none;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo {
    min-width: calc(18.0625rem + 8.9375
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    min-width: 27rem;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo {
    margin: 0 auto;
  }
}
@media screen and (min-width: 30rem) {
  .company_logo {
    margin: 0 auto;
  }
}
@media screen and (min-width: 35rem) {
  .company_logo {
    margin: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .company_logo {
    margin: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .company_logo {
    margin: 0;
  }
}
@media screen and (min-width: 75rem) {
  .company_logo {
    margin: 0;
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    margin: 0;
  }
}
@media screen and (min-width: 35rem) {
  .lobu_downloads .row div:nth-child(odd) .entry:after {
    display: block;
  }
}
@media screen and (min-width: 61.875rem) {
  .dropdown_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 61.875rem) {
  .dropdown_menu > ul > li {
    padding: 0.875rem 0;
  }
}
@media screen and (min-width: 35rem) {
  footer .related_links ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  footer .related_links ul li {
    margin-left: 0.75em;
  }
  footer .related_links li:before {
    color: #fff;
    content: "|";
    display: inline-block;
    margin-right: 0.75em;
  }
  footer .related_links li:first-of-type:before {
    display: none;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 35rem) {
  button {
    width: auto;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 20rem) {
  .nav_placeholder {
    height: calc(3.75rem + 1.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .nav_placeholder {
    height: 5rem;
  }
}
@media screen and (min-width: 20rem) {
  .nav_wrapper {
    height: calc(3.75rem + 1.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .nav_wrapper {
    height: 5rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_navigation_container .header_navigation {
    -webkit-box-pack: center;
            justify-content: center;
  }
}
@media screen and (min-width: 47.5rem) {
  .call {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .slogan p {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    padding: 1.5em 0;
  }
}
@media screen and (min-width: 20rem) {
  .slogan_small_text {
    font-size: calc(0.875rem + 0.5
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .slogan_small_text {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .slogan_large_text {
    font-size: calc(1.25rem + 1.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .slogan_large_text {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .slogan {
    bottom: 0;
    top: unset;
  }
}
@media screen and (min-width: 20rem) {
  .teaser_isartax_small_text {
    font-size: calc(0.875rem + 0.5
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser_isartax_small_text {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .teaser_isartax_large_text {
    font-size: calc(1.25rem + 1.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser_isartax_large_text {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .teaser_isartax {
    bottom: auto;
    top: 50%;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.arbeitnehmer_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.arbeitnehmer_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  .arbeitnehmer_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .arbeitnehmer_online_text {
    font-size: 65%;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.u_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.u_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  .u_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .u_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 61.875rem) {
  .u_online_benefits_flow .table {
    display: table;
    margin: 0 auto;
    padding: 0;
  }
  .u_online_benefits_flow .table_cell {
    display: table-cell;
    padding: 0 1.5em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .table_cell.text {
    max-width: 31.25rem;
  }
  .u_online_benefits_flow .arrow_large {
    display: block;
  }
  .u_online_benefits_flow .arrow_small {
    display: none;
  }
  .u_online_benefits_flow .flow_text {
    display: block;
  }
  .u_online_benefits_flow .header {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .header .text {
    display: block;
    margin: 0 auto 0.75em;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .footer {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .footer .text {
    display: block;
    margin: 0.75em auto 0;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .footer .arrow_large {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .description {
    max-height: none;
    overflow: none;
    padding: 0 0.8em;
  }
  .u_online_benefits_flow .lock {
    margin-bottom: 1.5em;
  }
  .u_online_benefits_flow .lock:before {
    height: 3.5rem;
    width: 3.5rem;
  }
  .u_online_benefits_flow .benefits_button {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 3em;
            column-gap: 3em;
  }
}
@media screen and (min-width: 35rem) {
  .newsletter_quick_subscribe form {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 61.875rem) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (max-width: 61.8125rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1 {
    top: 50%;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: width 300ms, top 300ms, -webkit-transform 300ms;
    transition: width 300ms, top 300ms, -webkit-transform 300ms;
    transition: transform 300ms, width 300ms, top 300ms;
    transition: transform 300ms, width 300ms, top 300ms, -webkit-transform 300ms;
    width: 70%;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3 {
    bottom: 50%;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: width 300ms, bottom 300ms, -webkit-transform 300ms;
    transition: width 300ms, bottom 300ms, -webkit-transform 300ms;
    transition: transform 300ms, width 300ms, bottom 300ms;
    transition: transform 300ms, width 300ms, bottom 300ms, -webkit-transform 300ms;
    width: 70%;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu {
    width: 21.875rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.5em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.5em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .search_box form[role="search"] button {
    margin-right: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .social_media_links {
    position: relative;
    bottom: auto;
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  .social_media_links .label {
    left: 10%;
    top: -60%;
    width: 4.375rem !important;
  }
}
@media screen and (min-width: 61.875rem) {
  .social_media_links .label {
    left: 0;
    top: -90%;
    width: 5.625rem !important;
  }
}
@media screen and (min-width: 47.5rem) {
  .social_media_links .label_wrapper_2 .label {
    left: -28%;
    top: -5%;
    width: 7.1875rem !important;
  }
}
@media screen and (min-width: 61.875rem) {
  .social_media_links .label_wrapper_2 .label {
    left: -61.5%;
    top: -19%;
    width: 11.25rem !important;
  }
}
@media screen and (min-width: 47.5rem) {
  .social_media_links .label_wrapper_1 .label {
    left: -12%;
  }
}
@media screen and (min-width: 61.875rem) {
  .social_media_links .label_wrapper_1 .label {
    left: -22%;
    top: -94%;
  }
}
@media screen and (min-width: 30rem) {
  footer .social_media_links .label_wrapper_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  footer .social_media_links .label_wrapper_2 {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  footer .social_media_links {
    display: none;
  }
}
@media screen and (min-width: 30rem) {
  .social_media_share a.print {
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  aside .steuernews_issues ul {
    display: block;
  }
}
@media screen and (min-width: 75rem) {
  aside .steuernews_issues ul {
    -webkit-column-count: 2;
            column-count: 2;
    position: relative;
  }
  aside .steuernews_issues ul:after {
    background-color: #DCE5F5;
    bottom: 0;
    content: "";
    display: block;
    left: 49%;
    position: absolute;
    top: 0;
    width: 0.3125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider {
    display: block;
    height: 16.125rem;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .steuernews_article_slider .slide {
    display: -webkit-box;
    display: flex;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibilty 300ms;
    transition: opacity 300ms, visibilty 300ms;
    visibility: hidden;
    z-index: 0;
  }
  .steuernews_article_slider .slide .image {
    min-width: 24.1875rem;
    width: 24.1875rem;
  }
  .steuernews_article_slider .slide .description {
    padding: 0 4.5em 0 3em;
    width: 100%;
  }
  .steuernews_article_slider .slide .description .title {
    margin: 0;
  }
  .steuernews_article_slider .slide .description .sub_title {
    display: block;
    margin-bottom: 0.75em;
  }
  .steuernews_article_slider .slide_anchor:checked + .slide {
    opacity: 1;
    visibility: visible;
    z-index: 5;
  }
  .steuernews_article_slider .control_slides {
    background: #e5e4e4;
    display: block;
    fill: #4c4b4b;
    height: 3.75rem;
    margin-top: -1.875rem;
    padding: 1em;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 3.75rem;
    z-index: 15;
  }
  .steuernews_article_slider .control_slides:focus,
  .steuernews_article_slider .control_slides:hover {
    fill: #164193;
    outline: 0;
  }
  .steuernews_article_slider .prev_slide {
    left: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .steuernews_article_slider .next_slide {
    right: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .news_image {
    display: block;
  }
}
@media screen and (min-width: 35rem) {
  .team_overview .team_entry .info_wrapper {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 35rem) {
  .team_overview .team_entry {
    margin-bottom: 3em;
  }
  .team_overview .team_entry .buttons {
    bottom: 0;
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .team_entry .info {
    padding-top: 0;
  }
}
@media screen and (min-width: 35rem) {
  .team_overview .photo {
    max-width: 25%;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 35rem) {
  .team_overview .info {
    margin: 1.2em 0 0 0;
  }
}
@media screen and (min-width: 75rem) {
  .team_overview .info {
    padding-top: 1.5em;
  }
}
@media screen and (min-width: 20rem) {
  .teaser_headline h2 {
    font-size: calc(1.4375rem + 1.0625
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser_headline h2 {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .special_teaser_overview .teaser {
    margin: 1.5em 7.5em;
  }
}
@media screen and (min-width: 61.875rem) {
  .special_teaser_overview .teaser {
    margin: 1.5em 0;
  }
}
@media screen and (min-width: 20rem) {
  .startpage_light_teaser .teaser .icon {
    bottom: calc(-6.25rem + -6.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage_light_teaser .teaser .icon {
    bottom: -12.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .startpage_light_teaser .teaser .icon {
    height: calc(15.625rem + 15.625
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage_light_teaser .teaser .icon {
    height: 31.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .startpage_light_teaser .teaser .icon {
    width: calc(15.625rem + 21.875
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage_light_teaser .teaser .icon {
    width: 37.5rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .startpage_light_teaser .teaser p {
    max-width: 50%;
  }
}
@media screen and (min-width: 47.5rem) {
  .startpage_dark_teaser .teaser p {
    max-width: 50%;
  }
}
@media screen and (min-width: 20rem) {
  .startpage_dark_teaser .teaser .icon {
    bottom: calc(-6.25rem + -6.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage_dark_teaser .teaser .icon {
    bottom: -12.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .startpage_dark_teaser .teaser .icon {
    height: calc(15.625rem + 21.875
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage_dark_teaser .teaser .icon {
    height: 37.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .startpage_dark_teaser .teaser .icon {
    width: calc(15.625rem + 21.875
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage_dark_teaser .teaser .icon {
    width: 37.5rem;
  }
}
@media screen and (min-width: 75rem) {
  .startpage_dark_teaser .teaser .button {
    margin-top: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .counter_overview ul {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (max-width: 47.4375rem) {
  .counter_overview ul li {
    margin: 1.5em 4.5em;
  }
}
@media screen and (min-width: 20rem) {
  .counter_overview ul li .number {
    font-size: calc(2.25rem + 2.75
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .counter_overview ul li .number {
    font-size: 5rem;
  }
}
@media screen and (min-width: 20rem) {
  .counter_overview ul li .text {
    font-size: calc(0.875rem + 0.625
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75rem) {
  .counter_overview ul li .text {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 34.9375rem) {
  .counter_overview ul li .text {
    margin: 0.75em 0;
  }
}
@media screen and (min-width: 93.75rem) {
  .counter_overview {
    padding: 3em 0 2.25em;
  }
}
@media print {
  .company_logo {
    display: block;
    margin-bottom: 0.75em;
    max-width: 18.75rem !important;
  }
  .company_logo.company_logo_2 {
    display: none;
  }
  .icon {
    display: none;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.5em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 400;
    margin-bottom: 0.75em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.5em;
  }
  .company_logo {
    display: block;
    max-width: 18.75rem;
  }
  .company_logo img {
    display: block;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.5em 0 0;
    padding: 0.75em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
